import * as React from 'react';
import { ReactElement, useState } from 'react';

import { gtmUserCustomEvent } from '../../utils';
import Spinner from '../common/Spinner';

import { useContainerContext } from './ContainerContext';
import { retake } from './api-rest-adapter';

export default function End(): ReactElement {
    const [endHasBeenTracked, setEndHasBeenTracked] = useState(false);
    const context = useContainerContext();
    const { displayHomeButton, progression, submitting } = context;

    if (!progression || !['finished', 'overdue'].includes(progression.status)) {
        return null;
    }

    if (!endHasBeenTracked) {
        setEndHasBeenTracked(true);
        gtmUserCustomEvent('evaluation_quiz_end', {
            evaluationQuizId: context.evaluationQuiz.id,
            evaluationQuizStatus: progression.status,
        });
    }

    const onRetakeClick = async (): Promise<void> => {
        if (submitting) {
            return;
        }
        await retake(context);
        context.setFirstAttempt(false);
        context.setDone(false);
        gtmUserCustomEvent('evaluation_quiz_retake', {
            evaluationQuizId: context.evaluationQuiz.id,
        });
    };

    return (
        <div className="overview">
            <div
                className="description"
                dangerouslySetInnerHTML={{ __html: progression.html }}
            />

            {progression.retakable && (
                <>
                    {submitting ? (
                        <Spinner />
                    ) : (
                        <div className="mb-2 text-left vstack">
                            {progression.score !== undefined && (
                                <span>
                                    Your current score: {progression.score}%
                                </span>
                            )}
                            <span>
                                Click{' '}
                                <a
                                    onClick={onRetakeClick}
                                    style={{ cursor: 'pointer' }}
                                >
                                    here
                                </a>{' '}
                                to retake the quiz for a chance to increase your
                                score.
                            </span>
                        </div>
                    )}
                </>
            )}

            {displayHomeButton && (
                <a className="btn btn-lg btn-primary" href="/">
                    Go back to home
                </a>
            )}
        </div>
    );
}
